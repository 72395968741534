import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ApiDiscoveryIcon = () => (
  <SvgIcon>
    <g>
      <path
        fill="#4285f4"
        d="M18.18,17.24a9,9,0,0,1-1.51,1.32l2.94,2.94a1,1,0,0,0,1.41,0h0a1,1,0,0,0,0-1.41Z"
      />
      <polygon
        fill="#4285f4"
        points="14 15.5 9.19 15.5 13.19 7.5 10 7.5 10 6.5 14.81 6.5 10.81 14.5 14 14.5 14 15.5"
      />
      <circle fill="#669df6" cx="14" cy="15" r="1.25" />
      <circle fill="#669df6" cx="10" cy="15" r="1.25" />
      <rect fill="#4285f4" x="8" y="10.5" width="8" height="1" />
      <path
        fill="#669df6"
        d="M12,4a7,7,0,1,1-7,7,7,7,0,0,1,7-7m0-2a9,9,0,1,0,9,9,9,9,0,0,0-9-9"
      />
      <circle fill="#669df6" cx="12" cy="11" r="1.25" />
      <circle fill="#669df6" cx="8" cy="11" r="1.25" />
      <circle fill="#669df6" cx="16" cy="11" r="1.25" />
      <circle fill="#669df6" cx="14" cy="7" r="1.25" />
      <circle fill="#669df6" cx="10" cy="7" r="1.25" />
    </g>
  </SvgIcon>
);

export { ApiDiscoveryIcon };
