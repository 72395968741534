import {
  createPlugin,
  createApiFactory,
  createRoutableExtension,
  googleAuthApiRef,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { Entity } from '@backstage/catalog-model';

import { rootRouteRef } from './routes';
import { FirebaseClient, firebaseApiRef } from './api';

export const firebasePlugin = createPlugin({
  id: 'firebase',
  routes: {
    entityContent: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: firebaseApiRef,

      deps: {
        googleAuthApi: googleAuthApiRef,
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory({ googleAuthApi, discoveryApi, fetchApi }) {
        return new FirebaseClient(googleAuthApi, discoveryApi, fetchApi);
      },
    }),
  ],
});

export const FirebasePage = firebasePlugin.provide(
  createRoutableExtension({
    name: 'FirebasePage',
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
export const EntityFirebaseHostingContent = firebasePlugin.provide(
  createRoutableExtension({
    name: 'EntityFirebaseHostingContent',
    component: () =>
      import('./components/EntityFirebaseHostingContent').then(
        m => m.EntityFirebaseHostingContent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const isFirebaseHostingAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.['firebase.google.com/hosting-site']);
