import { GeminiApi } from './GeminiApi';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

import { Message } from '../components/VertexAiPlayground/types';

/** @public */
export class GeminiClient implements GeminiApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
  ) {}

  async getVertexAiCompletion(
    model: string,
    messages: Message[],
    temperature: number,
    maxTokens: number,
  ): Promise<Response> {
    const baseUrl = await this.discoveryApi.getBaseUrl('gemini');

    const url = new URL(`${baseUrl}/completions`);
    url.search = new URLSearchParams({
      model: model,
      messages: JSON.stringify(messages),
      temperature: temperature.toString(),
      maxTokens: maxTokens.toString(),
    }).toString();

    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  }
}
