import { createApiRef } from '@backstage/core-plugin-api';

export interface WeatherApi {
  getStationId(): Promise<string>;
  getCurrentObservation(): Promise<any>;
  getDailyObservations(): Promise<any>;
  getDailySummary(): Promise<any>;
  getWeeklySummaries(): Promise<any>;
  getMonthlySummaries(): Promise<any>;
}

export const weatherApiRef = createApiRef<WeatherApi>({
  id: 'plugin.weather.api',
});
