import { ApigeeApi } from './ApigeeApi';
import {
  OAuthApi,
  DiscoveryApi,
  FetchApi,
  ConfigApi,
} from '@backstage/core-plugin-api';

import {
  ApigeeGetProductResponseData,
  ApigeeGetProxyResponseData,
  ApigeeGetEnvironmentResponseData,
  ApigeeGetOrganizationResponseData,
  ApigeeGetProductResponse,
  ApigeeGetProxyResponse,
  ApigeeGetEnvironmentResponse,
  ApigeeGetOrganizationResponse,
  ApigeeListEnvironmentsResponse,
  ApigeeListProductsResponse,
  ApigeeListProxiesResponse,
  ApigeeSecurityIncidentsResponse,
  ApigeeSecurityIncidentsResponseData,
  ApigeeEnvironmentStatsResponse,
  ApigeeProductStatsResponse,
} from './types';

export interface ApigeeClientOptions {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  googleAuthApi: OAuthApi;
  configApi: ConfigApi;
}

/** @public */
export class ApigeeClient implements ApigeeApi {
  constructor(
    private readonly googleAuthApi: OAuthApi,
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
  ) {}

  async getToken(): Promise<string> {
    return this.googleAuthApi.getAccessToken(
      'https://www.googleapis.com/auth/cloud-platform',
    );
  }

  async importOrg(namespace: string, org: string): Promise<void> {
    const baseUrl = await this.discoveryApi.getBaseUrl('events');
    // const { token } = await this.identityApi.getCredentials();

    const url = `${baseUrl}/http/apigee`;
    await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ namespace: namespace, organization: org }),
    });
  }

  async getEnvironmentStats(
    org: string,
    env: string,
    timeRange: string,
    timeUnit: string,
  ): Promise<ApigeeEnvironmentStatsResponse> {
    // const url = new URL(`${BASE_URL}/${org}/environments/${env}/stats`);

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/apigee/organizations/${org}/environments/${env}/stats`,
    );
    url.search = new URLSearchParams({
      select:
        'sum(message_count),avg(total_response_time),avg(request_size),avg(response_size)',
      timeRange,
      timeUnit,
    }).toString();

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data = await response.json();
    return { status: response.status, data };
  }

  // TODO: default-dev is hardcoded
  async getProductStats(
    org: string,
    product: string,
    timeRange: string,
    timeUnit: string,
  ): Promise<ApigeeProductStatsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/apigee/organizations/${org}/environments/default-dev/stats/apiproducts`,
    );

    url.search = new URLSearchParams({
      select: 'sum(message_count)',
      timeRange,
      timeUnit,
      filter: `(api_product eq '${product}')`,
    }).toString();

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data = await response.json();
    return { status: response.status, data };
  }

  async listEnvironments(org: string): Promise<ApigeeListEnvironmentsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}/environments`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data = await response.json();
    return { status: response.status, data };
  }

  async listProducts(org: string): Promise<ApigeeListProductsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}/apiproducts`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data = await response.json();
    return { status: response.status, data };
  }

  async listProxies(org: string): Promise<ApigeeListProxiesResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}/apis`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data = await response.json();
    return { status: response.status, data };
  }

  async getEnvironment(
    org: string,
    envName: string,
  ): Promise<ApigeeGetEnvironmentResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}/environments/${envName}`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: ApigeeGetEnvironmentResponseData = await response.json();
    return { status: response.status, data };
  }

  async getOrganization(org: string): Promise<ApigeeGetOrganizationResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      // eslint-disable-next-line no-console
      console.log(
        `List request failed to ${url} with ${response.status} ${response.statusText}`,
      );
      return { status: response.status };
    }

    const data: ApigeeGetOrganizationResponseData = await response.json();

    return { status: response.status, data };
  }

  async getProduct(
    org: string,
    productName: string,
  ): Promise<ApigeeGetProductResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}/apiproducts/${productName}`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }
    const data: ApigeeGetProductResponseData = await response.json();

    return { status: response.status, data };
  }

  async getProxy(
    org: string,
    proxyName: string,
  ): Promise<ApigeeGetProxyResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}/apis/${proxyName}`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: ApigeeGetProxyResponseData = await response.json();
    return { status: response.status, data };
  }

  async getSecurityIncidents(
    org: string,
    envName: string,
  ): Promise<ApigeeSecurityIncidentsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = `${proxyUrl}/apigee/organizations/${org}/environments/${envName}/securityIncidents`;

    const response = await this.fetchApi.fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }
    const data: ApigeeSecurityIncidentsResponseData = await response.json();
    return { status: response.status, data };
  }
}
