/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { CloudbuildApi } from './CloudbuildApi';
import {
  ActionsListWorkflowRunsForRepoResponseData,
  ActionsGetWorkflowResponseData,
} from '../api/types';
import { DiscoveryApi, OAuthApi } from '@backstage/core-plugin-api';

/** @public */
export class CloudbuildClient implements CloudbuildApi {
  constructor(
    private readonly googleAuthApi: OAuthApi,
    private readonly discoveryApi: DiscoveryApi,
  ) {}

  async reRunWorkflow(options: {
    projectId: string;
    location: string;
    runId: string;
  }): Promise<void> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/cloudbuild/projects/${options.projectId}/locations/${
        options.location
      }/builds/${encodeURIComponent(options.runId)}:retry`,
    );

    await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      }),
    });
  }

  async listWorkflowRuns(options: {
    projectId: string;
    location: string;
    repoName: string;
  }): Promise<ActionsListWorkflowRunsForRepoResponseData> {
    // eslint-disable-next-line no-console
    const { projectId, location, repoName } = options;
    const filter = repoName ? `substitutions.REPO_FULL_NAME=${repoName}` : '';

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/cloudbuild/projects/${projectId}/locations/${location}/builds?filter=${filter}`,
    );

    const workflowRuns = await fetch(url, {
      headers: new Headers({
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      }),
    });

    const builds: ActionsListWorkflowRunsForRepoResponseData =
      await workflowRuns.json();

    return builds;
  }

  async getWorkflow(options: {
    projectId: string;
    location: string;
    id: string;
  }): Promise<ActionsGetWorkflowResponseData> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/cloudbuild/projects/${options.projectId}/locations/${
        options.location
      }/builds/${encodeURIComponent(options.id)}`,
    );

    const workflow = await fetch(url, {
      headers: new Headers({
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      }),
    });

    const build: ActionsGetWorkflowResponseData = await workflow.json();

    return build;
  }

  async getWorkflowRun(options: {
    projectId: string;
    location: string;
    id: string;
  }): Promise<ActionsGetWorkflowResponseData> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/cloudbuild/projects/${options.projectId}/locations/${
        options.projectId
      }/locations/${options.location}/builds/${encodeURIComponent(options.id)}`,
    );
    const workflow = await fetch(url, {
      headers: new Headers({
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      }),
    });
    const build: ActionsGetWorkflowResponseData = await workflow.json();

    return build;
  }

  async getToken(): Promise<string> {
    return this.googleAuthApi.getAccessToken(
      'https://www.googleapis.com/auth/cloud-platform',
    );
  }
}
