import { createApiRef } from '@backstage/core-plugin-api';

import { Message } from '../components/VertexAiPlayground/types';

/** @public */
export const geminiApiRef = createApiRef<GeminiApi>({
  id: 'plugin.gemini.service',
});

/** @public */
export type GeminiApi = {
  getVertexAiCompletion(
    model: string,
    messages: Message[],
    temperature: number,
    maxTokens: number,
  ): Promise<Response>;
};
