import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

import { WeatherApi } from './types';

export class WeatherClient implements WeatherApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
  ) {}

  async getStationId(): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('weather');

    const res = await this.fetchApi.fetch(`${baseUrl}/v1/stationId`, {});

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data.stationId;
  }

  async getCurrentObservation(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('weather');

    const res = await this.fetchApi.fetch(
      `${baseUrl}/v1/currentObservations`,
      {},
    );

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }

  async getDailyObservations(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('weather');

    const res = await this.fetchApi.fetch(
      `${baseUrl}/v1/dailyObservations`,
      {},
    );

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }

  async getDailySummary(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('weather');

    const res = await this.fetchApi.fetch(`${baseUrl}/v1/dailySummary`, {});

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }

  async getWeeklySummaries(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('weather');

    const res = await this.fetchApi.fetch(`${baseUrl}/v1/weeklySummaries`, {});

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }

  async getMonthlySummaries(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('weather');

    const res = await this.fetchApi.fetch(`${baseUrl}/v1/monthlySummaries`, {});

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }
}
