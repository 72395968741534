import {
  createApiFactory,
  createPlugin,
  googleAuthApiRef,
  configApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { bigqueryApiRef, BigQueryClient } from './api';

export const costInsightsGcpPlugin = createPlugin({
  id: 'costinsights-gcp',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: bigqueryApiRef,
      deps: {
        googleAuthApi: googleAuthApiRef,
        configApi: configApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory({ googleAuthApi, configApi, discoveryApi }) {
        return new BigQueryClient({ googleAuthApi, configApi, discoveryApi });
      },
    }),
  ],
});
