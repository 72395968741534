import {
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { geminiApiRef, GeminiClient } from './api';

export const geminiPlugin = createPlugin({
  id: 'gemini',
  apis: [
    createApiFactory({
      api: geminiApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory({ discoveryApi, fetchApi }) {
        return new GeminiClient(discoveryApi, fetchApi);
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const GeminiPage = geminiPlugin.provide(
  createRoutableExtension({
    name: 'GeminiPage',
    component: () => import('./components/GeminiPage').then(m => m.GeminiPage),
    mountPoint: rootRouteRef,
  }),
);
