/* eslint-disable no-console */
/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
  This is a copy-pastable client template to get up and running quickly.
  API Reference:
  https://github.com/backstage/backstage/blob/master/plugins/cost-insights/src/api/CostInsightsApi.ts
*/

import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,
} from '@backstage-community/plugin-cost-insights';

import { OAuthApi, DiscoveryApi, ConfigApi } from '@backstage/core-plugin-api';

import { BigQueryClient } from '../api';

import regression, { DataPoint } from 'regression';
import { DateTime } from 'luxon';

import {
  Group,
  Cost,
  Entity,
  MetricData,
  Project,
  DateAggregation,
  Trendline,
} from '@backstage-community/plugin-cost-insights-common';

interface GcpCostInsightsClientOptions {
  googleAuthApi: OAuthApi;
  configApi: ConfigApi;
  discoveryApi: DiscoveryApi;
}
export class GcpCostInsightsClient implements CostInsightsApi {
  private bqClient;

  constructor(options: GcpCostInsightsClientOptions) {
    this.bqClient = new BigQueryClient(options);
  }

  private trendlineOf = (aggregation: DateAggregation[]): Trendline => {
    const data: ReadonlyArray<DataPoint> = aggregation.map(a => [
      DateTime.fromFormat(a.date, 'yyyy-MM-dd').toMillis(),
      a.amount,
    ]);
    const result = regression.linear(data, { precision: 5 });
    return {
      slope: result.equation[0],
      intercept: result.equation[1],
    };
  };

  async getLastCompleteBillingDate(): Promise<string> {
    return DateTime.now().toFormat('yyyy-MM-dd');
  }

  async getUserGroups(userId: string): Promise<Group[]> {
    console.log(userId);
    return [
      { id: 'bap-ce-apac', name: 'BAP CE' },
      // { id: 'bap-ce-apac2', name: 'BAP CE2' },
    ];
  }

  async getGroupProjects(group: string): Promise<Project[]> {
    console.log('getGroupProjects', group);

    const { projects, status } = await this.bqClient.getProjects();
    if (status !== 200) console.log(status);

    console.log(projects);
    return projects;
  }

  async getAlerts(group: string): Promise<Alert[]> {
    console.log('getAlerts', group);

    return [];
  }

  async getDailyMetricData(
    metric: string,
    intervals: string,
  ): Promise<MetricData> {
    console.log('getDailyMetricData', metric, intervals);

    // TODO

    const { metricData, status } = await this.bqClient.getDailyMetricData(
      metric,
      intervals,
    );
    if (status !== 200) console.log(status);
    return {
      id: 'getDailyMetricData',
      format: 'number',
      aggregation: metricData,
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
    console.log('getGroupDailyCost', group, intervals);

    const { totalCosts, groupedProductCosts, groupedProjectCosts, status } =
      await this.bqClient.getGroupDailyCost(group, intervals);
    if (status !== 200) console.log(status);
    return {
      id: 'getGroupDailyCost',
      aggregation: totalCosts,
      trendline: this.trendlineOf(totalCosts),

      change: {
        ratio: 0,
        amount: 0,
      },
      groupedCosts: {
        product: groupedProductCosts,
        project: groupedProjectCosts,
      },
    };
  }

  async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
    console.log('getProjectDailyCost', project, intervals);

    const { totalCosts, groupedProductCosts, status } =
      await this.bqClient.getProjectDailyCost(project, intervals);

    if (status !== 200) console.log(status);

    return {
      id: 'getProjectDailyCost',
      aggregation: totalCosts,
      trendline: this.trendlineOf(totalCosts),

      change: {
        ratio: 0,
        amount: 0,
      },
      groupedCosts: {
        product: groupedProductCosts,
      },
    };
  }

  async getCatalogEntityDailyCost(
    catalogEntityRef: string,
    intervals: string,
  ): Promise<Cost> {
    console.log('getCatalogEntityDailyCost', catalogEntityRef, intervals);

    return {
      id: 'remove-me',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
    console.log('getProductInsights', options);

    return {
      id: 'apigee',
      aggregation: [100000, 158000],
      change: {
        ratio: 0.58,
        amount: 58000,
      },
      entities: {
        environment: [
          {
            id: 'default-dev',
            aggregation: [10000, 12000],
            change: {
              ratio: 0.2,
              amount: 2000,
            },
            entities: {
              SKU: [
                {
                  id: 'Mock SKU A',
                  aggregation: [3000, 4000],
                  change: {
                    ratio: 0.333333,
                    amount: 1000,
                  },
                  entities: {},
                },
                {
                  id: 'Mock SKU B',
                  aggregation: [7000, 8000],
                  change: {
                    ratio: 0.14285714,
                    amount: 1000,
                  },
                  entities: {},
                },
              ],
            },
          },
          {
            id: 'default-test',
            aggregation: [60000, 70000],
            change: {
              ratio: 0.16666666666666666,
              amount: 10000,
            },
            entities: {
              SKU: [
                {
                  id: 'Mock SKU A',
                  aggregation: [20000, 15000],
                  change: {
                    ratio: -0.25,
                    amount: -5000,
                  },
                  entities: {},
                },
                {
                  id: 'Mock SKU B',
                  aggregation: [30000, 35000],
                  change: {
                    ratio: -0.16666666666666666,
                    amount: -5000,
                  },
                  entities: {},
                },
                {
                  id: 'Mock SKU C',
                  aggregation: [10000, 20000],
                  change: {
                    ratio: 1,
                    amount: 10000,
                  },
                  entities: {},
                },
              ],
            },
          },
          {
            id: 'default-prod',
            aggregation: [12000, 8000],
            change: {
              ratio: -0.33333,
              amount: -4000,
            },
            entities: {
              SKU: [
                {
                  id: 'Mock SKU A',
                  aggregation: [4000, 4000],
                  change: {
                    ratio: 0,
                    amount: 0,
                  },
                  entities: {},
                },
                {
                  id: 'Mock SKU B',
                  aggregation: [8000, 4000],
                  change: {
                    ratio: -0.5,
                    amount: -4000,
                  },
                  entities: {},
                },
              ],
            },
          },
        ],
      },
    };
  }
}
