import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  HomePageRandomJoke,
  HomePageStarredEntities,
  HeaderWorldClock,
  ClockConfig,
  HomePageToolkit,
  // TemplateBackstageLogoIcon,
  // TemplateBackstageLogo,
  HomePageCompanyLogo,
  // HomePageStackOverflowQuestions
} from '@backstage/plugin-home';

import { Content, Header, PageWithHeader } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import { HomePageSearchBar } from '@backstage/plugin-search';

// import { ApihubIcon } from '@internal/plugin-apihub';
import { ApigeeIcon } from '@internal/backstage-plugin-apigee';
// import { GcpIcon } from '@internal/plugin-gcp-projects';

import Logo from '../Root/AltostratLogoLarge.svg';
import { WeatherCard } from '@internal/backstage-plugin-weather';

const clockConfigs: ClockConfig[] = [
  {
    label: 'SYD',
    timeZone: 'Australia/Sydney',
  },
  {
    label: 'SGP',
    timeZone: 'Asia/Singapore',
  },
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
];

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 'auto',
    height: 100,
  },
  path: {
    fill: '#7df3e1',
  },
}));

export const HomePage = () => {
  const { container } = useLogoStyles();

  return (
    <PageWithHeader title={undefined} themeId="home">
      <Header
        title="Altostrat Internal Developer Portal"
        // subtitle="powered by Backstage"
      >
        <HeaderWorldClock clockConfigs={clockConfigs} />
      </Header>
      <Content>
        <Grid container justifyContent="center" spacing={6}>
          <HomePageCompanyLogo
            className={container}
            // logo={<TemplateBackstageLogo classes={{ svg, path }} />}
            logo={<img src={Logo} width="360" alt="React Logo" />}
          />
        </Grid>

        <Grid container justifyContent="center" spacing={6}>
          <Grid container item xs={12} alignItems="center" direction="row">
            <HomePageSearchBar placeholder="Search" />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <HomePageToolkit
              title="Tools"
              tools={[
                {
                  url: 'apigee',
                  label: 'Apigee',
                  icon: <ApigeeIcon />,
                },
                // {
                //   url: 'apihub',
                //   label: 'API Hub',
                //   icon: <ApihubIcon />,
                // },
                // {
                //   url: 'gcp',
                //   label: 'GCP Projects',
                //   icon: <GcpIcon />,
                // },
              ]}
              // Renderer={ExpandedComponentAccordion}
            />
          </Grid>
          <Grid item xs={6}>
            <HomePageStarredEntities />
          </Grid>

          {/* <Grid item xs={12} md={6}>
            <HomePageStackOverflowQuestions
              requestParams={{
                tagged: 'backstage',
                site: 'stackoverflow',
                pagesize: 5,
              }}
              icon={<StackOverflowIcon />}
            />
          </Grid> */}
          <Grid item xs={6}>
            <HomePageRandomJoke />
          </Grid>
          <Grid item xs={6}>
            <WeatherCard />
          </Grid>
        </Grid>
      </Content>
    </PageWithHeader>
  );
};
