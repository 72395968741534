import { createApiRef } from '@backstage/core-plugin-api';

/** @public */
export const bigqueryApiRef = createApiRef<BigQueryApi>({
  id: 'plugin.costinsights-gcp.service',
});

/** @public */
export type BigQueryApi = {
  getGroupDailyCost(group: string, intervals: string): Promise<any>;
  getProjectDailyCost(project: string, intervals: string): Promise<any>;
  getDailyMetricData(metric: string, intervals: string): Promise<any>;
  getProjects(): Promise<any>;
};
