import { createApiRef } from '@backstage/core-plugin-api';

import {
  GetConnectorResponse,
  GetIntegrationVersionsResponse,
  ListConnectorsResponse,
  ListIntegrationsResponse,
  ListIntegrationExecutionsResponse,
  GetMetricCountResponse,
} from '../types';

/** @public */
export const integrationsApiRef = createApiRef<IntegrationsApi>({
  id: 'plugin.integrations.service',
});

/** @public */
export type IntegrationsApi = {
  listIntegrations(locations: string[]): Promise<ListIntegrationsResponse>;
  getIntegrationVersions(id: string): Promise<GetIntegrationVersionsResponse>;
  getConnector(id: string): Promise<GetConnectorResponse>;
  listConnectors(locations: string[]): Promise<ListConnectorsResponse>;
  getMetricCount(
    projectId: string,
    integration: string,
    metric: string,
    interval: string,
  ): Promise<GetMetricCountResponse>;
  listIntegrationExecutions(
    id: string,
  ): Promise<ListIntegrationExecutionsResponse>;
};
