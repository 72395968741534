import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  googleAuthApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { costInsightsApiRef } from '@backstage-community/plugin-cost-insights';

import { GcpCostInsightsClient } from '@internal/plugin-costinsights-gcp';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: costInsightsApiRef,
    deps: {
      googleAuthApi: googleAuthApiRef,
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
    },

    factory: ({ googleAuthApi, configApi, discoveryApi }) =>
      new GcpCostInsightsClient({ googleAuthApi, configApi, discoveryApi }),
  }),
];
