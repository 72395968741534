import { createApiRef } from '@backstage/core-plugin-api';

import {
  ApigeeGetProxyResponse,
  ApigeeGetProductResponse,
  ApigeeGetEnvironmentResponse,
  ApigeeGetOrganizationResponse,
  ApigeeListEnvironmentsResponse,
  ApigeeListProductsResponse,
  ApigeeListProxiesResponse,
  ApigeeSecurityIncidentsResponse,
  ApigeeEnvironmentStatsResponse,
  ApigeeProductStatsResponse,
} from './types';
/** @public */
export const apigeeApiRef = createApiRef<ApigeeApi>({
  id: 'plugin.apigee.service',
});

/** @public */
export type ApigeeApi = {
  importOrg(namespace: string, org: string): Promise<void>;

  getOrganization(org: string): Promise<ApigeeGetOrganizationResponse>;
  getEnvironment(
    org: string,
    envName: string,
  ): Promise<ApigeeGetEnvironmentResponse>;
  getProduct(
    org: string,
    productName: string,
  ): Promise<ApigeeGetProductResponse>;
  getProxy(org: string, proxyName: string): Promise<ApigeeGetProxyResponse>;

  listEnvironments(org: string): Promise<ApigeeListEnvironmentsResponse>;
  listProducts(org: string): Promise<ApigeeListProductsResponse>;
  listProxies(org: string): Promise<ApigeeListProxiesResponse>;

  getEnvironmentStats(
    org: string,
    env: string,
    timeRange: string,
    timeUnit: string,
  ): Promise<ApigeeEnvironmentStatsResponse>;
  getProductStats(
    org: string,
    env: string,
    timeRange: string,
    timeUnit: string,
  ): Promise<ApigeeProductStatsResponse>;
  getSecurityIncidents(
    org: string,
    envName: string,
  ): Promise<ApigeeSecurityIncidentsResponse>;
};
