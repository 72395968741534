import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { weatherApiRef, WeatherClient } from './api';

export const weatherPlugin = createPlugin({
  id: 'weather',
  apis: [
    createApiFactory({
      api: weatherApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory({ discoveryApi, fetchApi }) {
        return new WeatherClient(discoveryApi, fetchApi);
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const WeatherPage = weatherPlugin.provide(
  createRoutableExtension({
    name: 'WeatherPage',
    component: () =>
      import('./components/WeatherComponent').then(m => m.WeatherComponent),
    mountPoint: rootRouteRef,
  }),
);

export const WeatherCard = weatherPlugin.provide(
  createRoutableExtension({
    name: 'WeatherCard',
    component: () =>
      import('./components/WeatherComponent').then(m => m.WeatherCard),
    mountPoint: rootRouteRef,
  }),
);
