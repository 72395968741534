import { IntegrationsApi } from './IntegrationsApi';
import { OAuthApi, DiscoveryApi } from '@backstage/core-plugin-api';

import {
  Connector,
  GetConnectorResponse,
  GetIntegrationVersionsResponse,
  GetIntegrationVersionsResponseData,
  ListConnectorsResponse,
  ListConnectorsResponseData,
  ListIntegrationsResponse,
  ListIntegrationsResponseData,
  ListIntegrationExecutionsResponse,
  ListIntegrationExecutionsResponseData,
  GetMetricCountResponse,
  GetMetricCountResponseData,
} from '../types';

/** @public */
export class IntegrationsClient implements IntegrationsApi {
  constructor(
    private readonly googleAuthApi: OAuthApi,
    private readonly discoveryApi: DiscoveryApi,
  ) {}

  async getToken(): Promise<string> {
    return this.googleAuthApi.getAccessToken(
      'https://www.googleapis.com/auth/cloud-platform',
    );
  }

  async getMetricCount(
    projectId: string,
    integration: string,
    metric: string,
    interval: string,
  ): Promise<GetMetricCountResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/monitoring/projects/${projectId}/timeSeries`,
    );

    // TODO: hardcoded date
    url.search = new URLSearchParams({
      filter: `metric.type="${metric}" AND resource.labels.integration="${integration}"`,
      'interval.endTime': '2024-06-30T15:01:23Z',
      'aggregation.alignmentPeriod': interval,
      'aggregation.crossSeriesReducer': 'REDUCE_SUM',
      'aggregation.perSeriesAligner': 'ALIGN_SUM',
    }).toString();

    const response: Response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: GetMetricCountResponseData = await response.json();

    return { status: 200, data };
  }

  async listIntegrations(
    locations: string[],
  ): Promise<ListIntegrationsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const results = await Promise.all(
      locations.map(async location => {
        const url = new URL(
          `${proxyUrl}/integrations/${location}/integrations`,
        );

        const response: Response = await fetch(url, {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${await this.getToken()}`,
          },
        });

        if (!response.ok) {
          return { status: response.status };
        }

        const data: ListIntegrationsResponseData = await response.json();
        data.integrations = data.integrations.map(integration => ({
          ...integration,
          location,
        }));
        // eslint-disable-next-line no-console
        console.log(data);

        return { status: 200, data };
      }),
    );

    // eslint-disable-next-line no-console
    console.log(results);

    const integrations = results
      .map(r => r.data?.integrations || [])
      .reduce((a, c) => [...a, ...c], []);

    return { status: 200, data: { integrations } };
  }

  async listConnectors(locations: string[]): Promise<ListConnectorsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const results = await Promise.all(
      locations.map(async location => {
        const url = new URL(`${proxyUrl}/connectors/${location}/connections`);

        const response: Response = await fetch(url, {
          headers: {
            Accept: '*/*',
            Authorization: `Bearer ${await this.getToken()}`,
          },
        });

        if (!response.ok) {
          return { status: response.status };
        }

        const data: ListConnectorsResponseData = await response.json();
        data.connections = data.connections.map(connection => ({
          ...connection,
          location,
        }));

        // eslint-disable-next-line no-console
        console.log(data);

        return { status: 200, data };
      }),
    );

    // eslint-disable-next-line no-console
    console.log(results);

    const connections = results
      .map(r => r.data?.connections || [])
      .reduce((a, c) => [...a, ...c], []);

    return { status: 200, data: { connections } };
  }

  async listIntegrationExecutions(
    id: string,
  ): Promise<ListIntegrationExecutionsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/integrations/${id}/executions`);

    const response: Response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: ListIntegrationExecutionsResponseData = await response.json();

    // eslint-disable-next-line no-console
    console.log(data);

    return { status: 200, data };
  }

  async getIntegrationVersions(
    id: string,
  ): Promise<GetIntegrationVersionsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/integrations/${id}/versions`);

    const response: Response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: GetIntegrationVersionsResponseData = await response.json();

    // eslint-disable-next-line no-console
    console.log(data);

    return { status: 200, data };
  }

  async getConnector(id: string): Promise<GetConnectorResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/connectors/${id}`);

    const response: Response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: Connector = await response.json();

    // eslint-disable-next-line no-console
    console.log(data);

    return { status: 200, data };
  }
}
