import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';

import { ApiDiscoveryApi } from './types';
import {
  AwsApiGateway,
  GcpApiGateway,
} from '@internal/backstage-plugin-apidiscovery-common';

export class ApiDiscoveryClient implements ApiDiscoveryApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
  ) {}

  async getAssets(assetTypes: string[]): Promise<string> {
    const baseUrl = await this.discoveryApi.getBaseUrl('apidiscovery');

    const url = new URL(`${baseUrl}/v1/assets`);
    url.search = new URLSearchParams(
      assetTypes.map(s => ['type', s]),
    ).toString();
    const res = await this.fetchApi.fetch(url, {});

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }

  async getGcpApiGateways(): Promise<GcpApiGateway[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('apidiscovery');

    const url = new URL(`${baseUrl}/v1/gcpapigateways`);

    const res = await this.fetchApi.fetch(url, {});

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }

  async getAwsApiGateways(): Promise<AwsApiGateway[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('apidiscovery');

    const url = new URL(`${baseUrl}/v1/awsapigateways`);

    const res = await this.fetchApi.fetch(url, {});

    if (!res.ok) {
      throw await ResponseError.fromResponse(res);
    }

    const data = await res.json();
    return data;
  }
}
