import { createPlugin } from '@backstage/core-plugin-api';

import {
  createSearchResultListItemExtension,
  SearchResultListItemExtensionProps,
} from '@backstage/plugin-search-react';

import { rootRouteRef } from './routes';
import { OpenapiSearchResultListItemProps } from './components/OpenapiSearchResultListItem';

export const openapiPlugin = createPlugin({
  id: 'openapi',
  routes: {
    root: rootRouteRef,
  },
});

export const OpenapiSearchResultListItem: (
  props: SearchResultListItemExtensionProps<OpenapiSearchResultListItemProps>,
) => JSX.Element | null = openapiPlugin.provide(
  createSearchResultListItemExtension({
    name: 'CatalogSearchResultListItem',
    component: () =>
      import('./components/OpenapiSearchResultListItem').then(
        m => m.OpenapiSearchResultListItem,
      ),
    predicate: result => result.type === 'openapi',
  }),
);
