import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  googleAuthApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { IntegrationsClient, integrationsApiRef } from './api';

export const applicationIntegrationPlugin = createPlugin({
  id: 'applicationintegration',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: integrationsApiRef,
      deps: {
        googleAuthApi: googleAuthApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory({ googleAuthApi, discoveryApi }) {
        return new IntegrationsClient(googleAuthApi, discoveryApi);
      },
    }),
  ],
});

export const ApplicationIntegrationPage = applicationIntegrationPlugin.provide(
  createRoutableExtension({
    name: 'ApplicationIntegrationPage',
    component: () => import('./Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
