import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ApplicationIntegrationIcon = () => (
  <SvgIcon>
    <g transform="scale(0.65),translate(4,0)">
      <path
        fill="none"
        stroke="#669df6"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M28.317 15.993H2.999"
      />
      <path
        d="M25.986 8.988h-4.179a5.6 5.6 0 00-3.587 1.3l-5.289 4.407a5.6 5.6 0 01-3.587 1.3H2.982"
        fill="none"
        stroke="#4285f4"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M21.988 3.992h-2.983a5.588 5.588 0 00-3.58 1.3l-5.278 4.4a5.6 5.6 0 01-3.58 1.3H2.981m0 10.008h3.57a5.589 5.589 0 013.582 1.3l5.282 4.4A5.6 5.6 0 0019 27.992h2.99"
        fill="none"
        stroke="#185ABC"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M4.332 15.993H9.3a5.6 5.6 0 013.581 1.3l5.28 4.4a5.594 5.594 0 003.582 1.3h4.24"
        fill="none"
        stroke="#4285f4"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <circle cx="25.975" cy="15.991" r="3" fill="#4285f4" />
      <circle cx="19.984" cy="8.988" r="3" fill="#669df6" />
      <circle cx="14" cy="25.992" r="3" fill="#669df6" />
    </g>
  </SvgIcon>
);

export { ApplicationIntegrationIcon };
