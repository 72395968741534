import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const FirebaseIcon = () => (
  <SvgIcon>
    <g transform="scale(0.65),translate(4,0)">
      <path
        d="M19.62 11.558l-3.203 2.98-2.972-5.995 1.538-3.448c.4-.7 1.024-.692 1.414 0z"
        fill="#ffa000"
      />
      <path d="M13.445 8.543l2.972 5.995-11.97 11.135z" fill="#f57f17" />
      <path
        d="M23.123 7.003c.572-.55 1.164-.362 1.315.417l3.116 18.105-10.328 6.2c-.36.2-1.32.286-1.32.286s-.874-.104-1.207-.3L4.447 25.673z"
        fill="#ffca28"
      />
      <path
        d="M13.445 8.543l-8.997 17.13L8.455.638c.148-.78.592-.855.988-.167z"
        fill="#ffa000"
      />
    </g>{' '}
  </SvgIcon>
);

export { FirebaseIcon };
