import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  googleAuthApiRef,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { apigeeApiRef, ApigeeClient } from './api';

export const apigeePlugin = createPlugin({
  id: 'apigee',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: apigeeApiRef,
      deps: {
        googleAuthApi: googleAuthApiRef,
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory({ googleAuthApi, discoveryApi, fetchApi }) {
        return new ApigeeClient(googleAuthApi, discoveryApi, fetchApi);
      },
    }),
  ],
});

export const ApigeePage = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeConsolePage',
    component: () => import('./components/ApigeePage').then(m => m.ApigeePage),
    mountPoint: rootRouteRef,
  }),
);

// export const ApigeeIcon = apigeePlugin.provide(
//   createRoutableExtension({
//     name: 'ApigeeIcon',
//     component: () => import('./components/ApigeeIcons').then(m => m.ApigeeIcon),
//     mountPoint: rootRouteRef,
//   }),
// );

export const ApigeeCatalogPage = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeCatalogPage',
    component: () =>
      import('./components/ApigeeCatalogPage').then(m => m.ApigeeCatalogPage),
    mountPoint: rootRouteRef,
  }),
);

export const ApigeeEntityAppContent = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeEntityAppContent',
    component: () =>
      import('./components/ApigeeEntityContent').then(
        m => m.ApigeeEntityAppContent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const ApigeeEntityDeploymentContent = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeEntityDeploymentContent',
    component: () =>
      import('./components/ApigeeEntityContent').then(
        m => m.ApigeeEntityDeploymentContent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const ApigeeEntityProductContent = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeEntityProductContent',
    component: () =>
      import('./components/ApigeeEntityContent').then(
        m => m.ApigeeEntityProductContent,
      ),
    mountPoint: rootRouteRef,
  }),
);
export const ApigeeEntityProxyContent = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeEntityProxyContent',
    component: () =>
      import('./components/ApigeeEntityContent').then(
        m => m.ApigeeEntityProxyContent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const ApigeeEntityDeveloperContent = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeEntityDeveloperContent',
    component: () =>
      import('./components/ApigeeEntityContent').then(
        m => m.ApigeeEntityDeveloperContent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const ApigeeEntityEnvironmentContent = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeEntityEnvironmentContent',
    component: () =>
      import('./components/ApigeeEntityContent').then(
        m => m.ApigeeEntityEnvironmentContent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const ApigeeEntityCredentialContent = apigeePlugin.provide(
  createRoutableExtension({
    name: 'ApigeeEntityCredentialContent',
    component: () =>
      import('./components/ApigeeEntityContent').then(
        m => m.ApigeeEntityCredentialContent,
      ),
    mountPoint: rootRouteRef,
  }),
);
