import { createApiRef } from '@backstage/core-plugin-api';

import {
  FirebaseGetAppResponse,
  FirebaseGetFunctionResponse,
  FirebaseGetSiteReleasesResponse,
  FirebaseGetSiteResponse,
  FirebaseListAppsResponse,
  FirebaseListFunctionsResponse,
  FirebaseListProjectsResponse,
  FirebaseListSitesResponse,
} from '../types';
/** @public */
export const firebaseApiRef = createApiRef<FirebaseApi>({
  id: 'plugin.firebase.service',
});

/** @public */
export type FirebaseApi = {
  listProjects(): Promise<FirebaseListProjectsResponse>;
  listApps(project: string): Promise<FirebaseListAppsResponse>;
  listSites(projectId: string): Promise<FirebaseListSitesResponse>;
  listFunctions(project: string): Promise<FirebaseListFunctionsResponse>;

  getApp(appId: string): Promise<FirebaseGetAppResponse>;
  getSite(siteId: string): Promise<FirebaseGetSiteResponse>;
  getSiteReleases(siteId: string): Promise<FirebaseGetSiteReleasesResponse>;
  getFunction(functionId: string): Promise<FirebaseGetFunctionResponse>;
};
