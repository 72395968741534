/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';

/** @public */
export const CLOUDBUILD_ANNOTATION = 'google.com/cloudbuild-projectId';
const CLOUDBUILD_LOCATION_ANNOTATION = 'google.com/cloudbuild-location';
const CLOUDBUILD_REPONAME_ANNOTATION = 'google.com/cloudbuild-repoName';

export const useCloudbuildAnnotations = (entity: Entity) => {
  const projectId = entity?.metadata.annotations?.[CLOUDBUILD_ANNOTATION] ?? '';
  const location =
    entity?.metadata.annotations?.[CLOUDBUILD_LOCATION_ANNOTATION] ?? 'global';
  const repoName =
    entity?.metadata.annotations?.[CLOUDBUILD_REPONAME_ANNOTATION] ?? '';
  return [projectId, location, repoName];
};

// import useAsync from 'react-use/lib/useAsync';
// import { Entity } from '@backstage/catalog-model';

// /** @public */
// export const CLOUDBUILD_ANNOTATION = 'google.com/cloudbuild-project-slug';

// export const useProjectName = (entity: Entity) => {
//   const { value, loading, error } = useAsync(async () => {
//     return entity?.metadata.annotations?.[CLOUDBUILD_ANNOTATION] ?? '';
//   });
//   return { value, loading, error };
// };
