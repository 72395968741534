/* eslint-disable no-console */
import { BigQueryApi } from './BigQueryApi';
import { DiscoveryApi, OAuthApi, ConfigApi } from '@backstage/core-plugin-api';

const dateWhereClause = (intervals: string): string => {
  const parts = intervals.split('/');

  const whereClause = `DATE(usage_start_time) between  DATE_SUB(PARSE_DATE('%Y-%m-%d', '${parts[2]}')`;

  switch (parts[1]) {
    case 'P30D':
      return `${whereClause}, INTERVAL 60 DAY) and PARSE_DATE('%Y-%m-%d', '${parts[2]}')`;

    case 'P3M':
      return `${whereClause}, INTERVAL 6 MONTH) and PARSE_DATE('%Y-%m-%d', '${parts[2]}')`;
    case 'P7D':
      return `${whereClause}, INTERVAL 14 DAY) and PARSE_DATE('%Y-%m-%d', '${parts[2]}')`;
    case 'P90D':
      return `${whereClause}, INTERVAL 180 DAY) and PARSE_DATE('%Y-%m-%d', '${parts[2]}')`;
    default:
      return `${whereClause}, , INTERVAL 1 DAY) and PARSE_DATE('%Y-%m-%d', '${parts[2]}')`;
  }
};
// const DATASET =
//   'altostrat-andyhood.billing_export.gcp_billing_export_v1_010924_F91CAA_1CD46E';

interface BigQueryClientOptions {
  googleAuthApi: OAuthApi;
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
}
/** @public */
export class BigQueryClient implements BigQueryApi {
  private dataset;
  private projectId;
  private readonly googleAuthApi: OAuthApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor(options: BigQueryClientOptions) {
    this.googleAuthApi = options.googleAuthApi;
    this.discoveryApi = options.discoveryApi;

    this.dataset = options.configApi.getString(
      'costInsightsGcp.billingExportTable',
    );
    this.projectId = options.configApi.getString(
      'costInsightsGcp.billingProject',
    );

    // console.log(
    // 'config',
    // config.getOptionalString('costInsightsGcp.billingExportTable'),
    //   this.dataset = config.getString('costInsightsGcp.billingExportTable');
    //   const projectId = config.getString('costInsightsGcp.billingProject');
  }

  async getToken(): Promise<string> {
    return this.googleAuthApi.getAccessToken(
      'https://www.googleapis.com/auth/cloud-platform',
    );
  }

  async getProjects(): Promise<any> {
    const sql = `
  SELECT
    distinct project.id
  FROM
    ${this.dataset}`;

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/bigquery/projects/${this.projectId}/queries`,
    );

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        query: sql,
        useLegacySql: false,
      }),

      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const { rows } = await response.json();

    const projects = rows?.map((r: any) => ({ id: r.f[0].v })) || [];

    return { status: response.status, projects };
  }

  async getProjectDailyCost(project: string, intervals: string): Promise<any> {
    // console.log('intervals', intervals);

    // console.log(dateWhereClause(intervals));

    const sql = `
  SELECT
    FORMAT_TIMESTAMP("%Y-%m-%d", usage_start_time) AS date,
    service.description AS serviceName,
    ROUND(SUM(cost),2) AS amount
  FROM
    ${this.dataset}
  WHERE
    ${dateWhereClause(intervals)} and project.id = '${project}'
  GROUP BY
    1,
    2
  UNION ALL
  SELECT
    FORMAT_TIMESTAMP("%Y-%m-%d", usage_start_time) AS date,
    'SUM' AS serviceName,
    ROUND(SUM(cost),2) AS amount
  FROM
    ${this.dataset}
  WHERE
    ${dateWhereClause(intervals)} and project.id = '${project}'
  GROUP BY
    1,
    2
  ORDER BY 1 DESC, 2`;

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/bigquery/projects/${this.projectId}/queries`,
    );

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        query: sql,
        useLegacySql: false,
      }),

      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    // console.log(response);

    if (!response.ok) {
      return { status: response.status };
    }

    const { rows } = await response.json();

    const products =
      rows
        ?.filter((r: any) => r.f[1].v !== null && r.f[1].v !== 'SUM')
        .reduce(
          (acc: string[], r: any) =>
            acc.includes(r.f[1].v) ? acc : [...acc, r.f[1].v],
          [],
        ) || [];

    const groupedProductCosts =
      products?.map((product: string) => {
        return {
          id: product,
          aggregation:
            rows
              ?.filter((r: any) => r.f[1].v === product)
              .map((r: any) => ({
                date: r.f[0].v,
                amount: parseFloat(r.f[2].v),
              })) || null,
        };
      }) || [];
    // console.log(groupedProductCosts);

    const totalCosts =
      rows
        ?.filter((r: any) => r.f[1].v === 'SUM')
        .map((r: any) => ({
          date: r.f[0].v,
          amount: parseFloat(r.f[2].v),
        })) || [];
    return { status: response.status, totalCosts, groupedProductCosts };
  }

  async getGroupDailyCost(group: string, intervals: string): Promise<any> {
    console.log(group, dateWhereClause(intervals));

    const sql = `
  SELECT
    FORMAT_TIMESTAMP("%Y-%m-%d", usage_start_time) AS date,
    service.description AS serviceName,
    NULL AS projectId,
    ROUND(SUM(cost),2) AS amount
  FROM
    ${this.dataset}
  WHERE
    ${dateWhereClause(intervals)}
  GROUP BY
    1,
    2
  UNION ALL
  SELECT
    FORMAT_TIMESTAMP("%Y-%m-%d", usage_start_time) AS date,
    'SUM' AS serviceName,
    NULL AS projectId,
    ROUND(SUM(cost),2) AS amount
  FROM
    ${this.dataset}
  WHERE
    ${dateWhereClause(intervals)}
  GROUP BY
    1,
    2
  UNION ALL
  SELECT
    FORMAT_TIMESTAMP("%Y-%m-%d", usage_start_time) AS date,
    NULL AS serviceName,
    project.id AS projectId,
    ROUND(SUM(cost),2) AS amount
  FROM
    ${this.dataset}
  WHERE
    ${dateWhereClause(intervals)}
  GROUP BY
    1,
    3`;

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/bigquery/projects/${this.projectId}/queries`,
    );

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        query: sql,
        useLegacySql: false,
      }),

      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const { rows } = await response.json();

    const projects =
      rows
        ?.filter((r: any) => r.f[2].v !== null)
        .reduce(
          (acc: string[], r: any) =>
            acc.includes(r.f[2].v) ? acc : [...acc, r.f[2].v],
          [],
        ) || [];

    const products =
      rows
        ?.filter((r: any) => r.f[1].v !== null && r.f[1].v !== 'SUM')
        .reduce(
          (acc: string[], r: any) =>
            acc.includes(r.f[1].v) ? acc : [...acc, r.f[1].v],
          [],
        ) || [];

    const groupedProjectCosts =
      projects?.map((project: string) => {
        return {
          id: project,
          aggregation:
            rows
              ?.filter((r: any) => r.f[2].v === project)
              .map((r: any) => ({
                date: r.f[0].v,
                amount: parseFloat(r.f[3].v),
              })) || null,
        };
      }) || [];
    // console.log(groupedProjectCosts);

    const groupedProductCosts =
      products?.map((product: string) => {
        return {
          id: product,
          aggregation:
            rows
              ?.filter((r: any) => r.f[1].v === product)
              .map((r: any) => ({
                date: r.f[0].v,
                amount: parseFloat(r.f[3].v),
              })) || null,
        };
      }) || [];
    // console.log(groupedProductCosts);

    const totalCosts =
      rows
        ?.filter((r: any) => r.f[1].v === 'SUM')
        .map((r: any) => ({
          date: r.f[0].v,
          amount: parseFloat(r.f[3].v),
        })) || [];
    return {
      status: response.status,
      totalCosts,
      groupedProjectCosts,
      groupedProductCosts,
    };
  }

  async getDailyMetricData(metric: string, intervals: string): Promise<any> {
    console.log(metric, dateWhereClause(intervals));
    // TODO fixup the dates
    const SKU = metric === 'apicalls' ? '1F65-3858-3EA2' : '0136-18C1-DD41';
    const sql = `
  SELECT
    FORMAT_TIMESTAMP("%Y-%m-%d", usage_start_time) AS date,
    SUM(usage.amount) AS amount
  FROM
    ${this.dataset}
  WHERE
    ${dateWhereClause(intervals)} AND
    service.description = 'Apigee' AND
    sku.id = '${SKU}'
  GROUP BY
    1
    
  UNION ALL
  SELECT
    FORMAT_TIMESTAMP("%Y-%m-%d", t) AS date, 0 as amount
  FROM 
    UNNEST(GENERATE_DATE_ARRAY('2023-01-01', '2023-08-11', INTERVAL 1 DAY)) AS t`;

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/bigquery/projects/${this.projectId}/queries`,
    );

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        query: sql,
        useLegacySql: false,
      }),

      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const { rows } = await response.json();

    // console.log('rows', rows);

    const metricData =
      rows?.map((r: any) => ({
        date: r.f[0].v,
        amount:
          metric === 'nodehours'
            ? parseFloat(r.f[1].v) / 3600
            : parseFloat(r.f[1].v),
      })) || [];
    return {
      status: response.status,
      metricData,
    };
  }
}
