import { FirebaseApi } from './FirebaseApi';
import { OAuthApi, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

import {
  FirebaseProject,
  Site,
  CloudFunction,
  FirebaseApp,
  FirebaseGetAppResponse,
  FirebaseGetFunctionResponse,
  FirebaseGetSiteReleasesResponse,
  FirebaseGetSiteReleasesResponseData,
  FirebaseGetSiteResponse,
  FirebaseListAppsResponse,
  FirebaseListAppsResponseData,
  FirebaseListFunctionsResponse,
  FirebaseListFunctionsResponseData,
  FirebaseListProjectsResponse,
  FirebaseListProjectsResponseData,
  FirebaseListSitesResponse,
  FirebaseListSitesResponseData,
} from '../types';

/** @public */
export class FirebaseClient implements FirebaseApi {
  constructor(
    private readonly googleAuthApi: OAuthApi,
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
  ) {}

  async getToken(): Promise<string> {
    return this.googleAuthApi.getAccessToken(
      'https://www.googleapis.com/auth/cloud-platform',
    );
  }

  async listProjects(): Promise<FirebaseListProjectsResponse> {
    let data: FirebaseListProjectsResponseData | undefined;
    const results: FirebaseProject[] = [];

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    // const u = new URL(`${proxyUrl}/httpbin/headers`);
    // const r = await this.fetchApi.fetch(u, {
    //   headers: {
    //     Authorization: `Bearer ${await this.getToken()}`,
    //   },
    // });
    // // eslint-disable-next-line no-console
    // console.log(await r.json());

    do {
      let url: URL;

      if (data?.nextPageToken) {
        url = new URL(
          `${proxyUrl}/firebase/projects?pageToken=${encodeURIComponent(
            data.nextPageToken,
          )}`,
        );
      } else {
        url = new URL(`${proxyUrl}/firebase/projects`);
      }
      // const response: Response = await fetch(url, {
      const response: Response = await this.fetchApi.fetch(url, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });

      if (!response.ok) {
        return { status: response.status };
      }

      data = await response.json();

      if (data) results.push(...data.results);
    } while (data && data.nextPageToken);

    return { status: 200, data: { results } };
  }

  private async listWebApps(
    projectId: string,
  ): Promise<FirebaseListAppsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/firebase/${projectId}/webApps`);

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: FirebaseListAppsResponseData = await response.json();
    return { status: response.status, data };
  }

  async getApp(appId: string): Promise<FirebaseGetAppResponse> {
    const appType = (a: string): string => {
      if (a.includes('android:')) return 'androidApps';
      if (a.includes('web:')) return 'webApps';
      if (a.includes('ios:')) return 'iosApps';
      return 'other';
    };

    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/firebase/projects/-/${appType(appId)}/${appId}`,
    );

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: FirebaseApp = await response.json();
    return { status: response.status, data };
  }

  private async listIosApps(
    projectId: string,
  ): Promise<FirebaseListAppsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/firebase/${projectId}/iosApps`);

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: FirebaseListAppsResponseData = await response.json();
    return { status: response.status, data };
  }

  private async listAndroidApps(
    projectId: string,
  ): Promise<FirebaseListAppsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/firebase/${projectId}/androidApps`);

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: FirebaseListAppsResponseData = await response.json();
    return { status: response.status, data };
  }

  async listApps(projectId: string): Promise<FirebaseListAppsResponse> {
    const results = await Promise.all([
      this.listAndroidApps(projectId),
      this.listIosApps(projectId),
      this.listWebApps(projectId),
    ]);

    // eslint-disable-next-line no-console
    console.log(`Results: ${JSON.stringify(results)}`);

    const apps = results
      .map(r => r.data?.apps || [])
      .reduce((a, c) => [...a, ...c], []);

    // eslint-disable-next-line no-console
    console.log(`Results: ${JSON.stringify(apps)}`);

    return { status: 200, data: { apps } };
  }

  async listFunctions(
    projectId: string,
  ): Promise<FirebaseListFunctionsResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(
      `${proxyUrl}/functions/${projectId}/locations/-/functions`,
    );

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: FirebaseListFunctionsResponseData = await response.json();
    return { status: response.status, data };
  }

  async getFunction(functionId: string): Promise<FirebaseGetFunctionResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/functions/${functionId}`);

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: CloudFunction = await response.json();
    return { status: response.status, data };
  }

  async listSites(projectId: string): Promise<FirebaseListSitesResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/hosting/${projectId}/sites`);

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: FirebaseListSitesResponseData = await response.json();
    return { status: response.status, data };
  }

  async getSite(siteId: string): Promise<FirebaseGetSiteResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/hosting/${siteId}`);

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: Site = await response.json();
    return { status: response.status, data };
  }

  async getSiteReleases(
    siteId: string,
  ): Promise<FirebaseGetSiteReleasesResponse> {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');

    const url = new URL(`${proxyUrl}/hosting/${siteId}/releases`);

    const response = await fetch(url, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${await this.getToken()}`,
      },
    });

    if (!response.ok) {
      return { status: response.status };
    }

    const data: FirebaseGetSiteReleasesResponseData = await response.json();
    return { status: response.status, data };
  }
}
