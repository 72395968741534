import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

const ApigeeIcon = () => (
  <SvgIcon>
    <title>Icon_24px_Color_AppigeeAPIPlatform</title>
    <g id="Group-2" transform="scale(0.85), translate(2,2)">
      <g id="Group-8">
        <path
          id="Fill-1"
          fill="#669df6"
          d="M5.2,12l1.32-1.32L4.73,8.89a2.94,2.94,0,0,1,2.08-5,2.92,2.92,0,0,1,2.07.86l1.8,1.79L12,5.2,10.21,3.41a4.81,4.81,0,1,0-6.8,6.8Z"
        />
        <path
          id="Fill-3"
          fill="#669df6"
          d="M18.8,12l-1.32,1.32,1.79,1.8a2.94,2.94,0,0,1-4.16,4.15l-1.79-1.79L12,18.8l1.79,1.79a4.81,4.81,0,1,0,6.8-6.8Z"
        />
        <g id="Group-7">
          <path
            id="Fill-5"
            fill="#4285f4"
            d="M8.89,19.27a2.94,2.94,0,0,1-4.16-4.16l1.79-1.79,4.16,4.16ZM15.12,4.73a2.93,2.93,0,0,1,5,2.08,3,3,0,0,1-.86,2.08l-1.79,1.79L13.32,6.52ZM12,15.19A3.2,3.2,0,0,1,8.81,12h0A3.21,3.21,0,0,1,12,8.81h0A3.21,3.21,0,0,1,15.19,12h0A3.2,3.2,0,0,1,12,15.19ZM18.8,12l1.79-1.79a4.81,4.81,0,1,0-6.8-6.8L12,5.2,5.2,12,3.41,13.79a4.81,4.81,0,1,0,6.8,6.8L12,18.8Z"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);

export { ApigeeIcon };
