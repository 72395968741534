import { createApiRef } from '@backstage/core-plugin-api';
import {
  GcpApiGateway,
  AwsApiGateway,
} from '@internal/backstage-plugin-apidiscovery-common';

export interface ApiDiscoveryApi {
  getAssets(assetTypes: string[]): Promise<any>;
  getAwsApiGateways(): Promise<AwsApiGateway[]>;
  getGcpApiGateways(): Promise<GcpApiGateway[]>;
}

export const apiDiscoveryApiRef = createApiRef<ApiDiscoveryApi>({
  id: 'plugin.apidiscovery.api',
});
