import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { ApiDiscoveryClient, apiDiscoveryApiRef } from './api';

export const apiDiscoveryPlugin = createPlugin({
  id: 'apidiscovery',
  apis: [
    createApiFactory({
      api: apiDiscoveryApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory({ discoveryApi, fetchApi }) {
        return new ApiDiscoveryClient(discoveryApi, fetchApi);
      },
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const ApiDiscoveryPage = apiDiscoveryPlugin.provide(
  createRoutableExtension({
    name: 'ApiDiscoveryPage',
    component: () =>
      import('./components/ApiDiscoveryComponent').then(
        m => m.ApiDiscoveryComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
